import { getCurrentInstance } from 'vue'; // getCurrentInstance is an internal function, use with caution
import type { BvModal } from 'bootstrap-vue';

export function useBvModal() {
  const app = getCurrentInstance();
  const warning = 'BootstrapModal not implemented';
  let bvModal:BvModal = {
    msgBoxOk: () => Promise.reject(new Error(warning)),
    msgBoxConfirm: () => Promise.reject(new Error(warning)),
    show: () => new Error(warning),
    hide: () => new Error(warning),
  };

  if (app?.proxy.$root.$bvModal) {
    bvModal = app.proxy.$root.$bvModal;
  }

  return {
    bvModal,
  };
}
