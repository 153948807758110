<template>
  <div
    v-if="getSetting('cookie_consent_enabled')"
    class="alert alert-primary"
    v-html="getSetting('cookie_consent_message')"
  />
</template>

<script setup lang="ts">
import { useSettings } from '@/composables';

const { getSetting } = useSettings();

</script>
