export function usePermissions() {
  const can = (permissionName) => window.permissions && window.permissions.includes(permissionName);
  const canAny = (permissionNames) => window.permissions && permissionNames.find((p) => window.permissions.includes(p)) !== undefined;
  const spouseCan = (permissionName) => window.spouse_permissions?.includes(permissionName);
  const spouseCanAny = (permissionNames) => window.spouse_permissions && permissionNames.find((p) => window.spouse_permissions.includes(p)) !== undefined;

  return {
    can,
    canAny,
    spouseCan,
    spouseCanAny,
  };
}
