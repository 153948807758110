<template>
  <div>
    <div v-if="authUser">
      <breadcrumb-header :items="breadcrumbs" />
    </div>
    <div v-else>
      <global-header title="Cookie Policy" />
    </div>
    <div
      class="content_paragraph"
      v-html="getSetting('cookie_policy')"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth, useSettings } from '@/composables';
import BreadcrumbHeader from '@/components/BreadcrumbHeader.vue';

const { getSetting } = useSettings();
const { authUser } = useAuth();

const breadcrumbs: Array<BreadCrumb> = [
  {
    text: 'Cookie Policy',
  },
];
</script>
