const config = window.config ?? [];

export function useConfig() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getConfig = (opt, defaultValue: any = undefined) => (config[opt] !== undefined ? config[opt] : defaultValue);

  return {
    getConfig,
  };
}
