<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="10">
        <b-breadcrumb
          v-if="breadcrumbs"
          :items="breadcrumbs"
        />
      </b-col>
      <b-col cols="2">
        <div>
          <b-btn
            v-if="canAny(['manage-shifts','read-all-shifts'])"
            data-testid="nav-shift-management"
            size="sm"
            href="/admin/shifts/schedule"
            class="mr-1"
          >
            <i class="icon-ico_calendar" />
          </b-btn>
          <b-btn
            v-if="can('manage-users')"
            data-testid="nav-shift-manage-users"
            href="/admin/users"
            size="sm"
            class="mr-1"
          >
            <i class="fas fa-user-friends" />
          </b-btn>
          <b-btn
            v-if="authUser.is_admin"
            data-testid="nav-admin"
            size="sm"
            href="/admin"
          >
            <i class="icon-ico_keys" />
          </b-btn>
        </div>
      </b-col>
    </b-row>
    <div class="content_divider" />
    <global-header :title="title" />
  </div>
</template>

<script setup lang="ts">
import { useAuth, usePermissions } from '@/composables';
import { computed, PropType } from 'vue';
import GlobalHeader from './GlobalHeader.vue';

const { can, canAny } = usePermissions();
const { authUser } = useAuth();
const homeCrumb: BreadCrumb = {
  text: 'Home',
  href: '/',
};
const adminCrumb: BreadCrumb = {
  text: 'Admin',
  href: '/admin',
};

const props = defineProps({
  items: {
    type: Array as PropType<BreadCrumb[]>,
    required: true,
  },
  admin: {
    type: Boolean,
    default: false,
  },
});

const breadcrumbs = computed(() => {
  const crumbs = [
    homeCrumb,
    ...props.admin ? [adminCrumb] : [],
    ...props.items.map((c) => ({
      ...c,
      active: false,
    })),
  ];

  crumbs[crumbs.length - 1].active = true;

  return crumbs;
});

const title = computed(() => {
  const crumbs = breadcrumbs.value;
  return crumbs[crumbs.length - 1].text;
});
</script>
