<template>
  <b-input-group>
    <b-form-input
      ref="pwd"
      v-model="password"
      :type="(showPassword) ? 'text' : 'password'"
      :name="name"
      data-testid="password"
      @change="onPasswordChange"
    />
    <b-input-group-append>
      <b-button
        text="Show/Hide Password"
        variant="primary"
        tabindex="-1"
        data-testid="password-toggle"
        @click="toggleShowPassword()"
      >
        <i :class="`far ${(showPassword) ? 'fa-eye-slash' : 'fa-eye'}`" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const showPassword = ref(false);
const pwd = ref<HTMLInputElement | null>(null);
const password = ref<string | undefined>('');

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: false,
  },
});

password.value = props.value;

const emit = defineEmits(['input']);

function onPasswordChange() {
  emit('input', password.value);
}

function toggleShowPassword() {
  showPassword.value = !showPassword.value;
  pwd.value?.focus();
}
</script>
