<template>
  <input
    type="hidden"
    name="_token"
    :value="crossSiteRequestForgeryToken"
  >
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const crossSiteRequestForgeryToken = ref('');

const metaTag = document.querySelector('meta[name="csrf-token"]');
if (metaTag && metaTag instanceof HTMLMetaElement && metaTag.content) {
  crossSiteRequestForgeryToken.value = metaTag.content;
} else {
  console.error('CSRF token not found');
}
</script>
